import {
  InputHTMLAttributes,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react"

const TextInputComponent = forwardRef(function TextInput(
  {
    type = "text",
    className = "",
    isFocused = false,
    ...props
  }: InputHTMLAttributes<HTMLInputElement> & { isFocused?: boolean },
  ref,
) {
  const localRef = useRef<HTMLInputElement>(null)

  useImperativeHandle(ref, () => ({
    focus: () => localRef.current?.focus(),
  }))

  useEffect(() => {
    if (isFocused) {
      localRef.current?.focus()
    }
  }, [isFocused])

  return (
    <input
      {...props}
      type={type}
      className={
        "rounded-md border-gray-300 text-sm font-normal leading-5 shadow-sm focus:border-cerulean-500 focus:ring-cerulean-500 " +
        className
      }
      ref={localRef}
    />
  )
})

TextInputComponent.displayName = "TextInput"

export default TextInputComponent
